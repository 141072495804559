import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
//import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage-angular';
import {SwUpdate} from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent  implements OnInit {

  constructor(
    private platform: Platform,
    //private splashScreen: SplashScreen,
    private storage: Storage,
    private swUpdate: SwUpdate
    //private statusBar: StatusBar
  ) {

    this.initializeApp();
  }

  initializeApp() {

	  this.platform.ready().then(() => {
		//this.statusBar.styleDefault();
		//this.splashScreen.hide();
	  })

  }
  async ngOnInit() {


    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if(confirm("Une nouvelle version est disponible. Voulez vous l'installer ?")) {
          window.location.reload();
        }
      });
    }

    // If using a custom driver:
    // await this.storage.defineDriver(MyCustomDriver)
    //await this.storage.create();
  }

}
